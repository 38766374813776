import http from '@/http';
import moment from 'moment';
import {formatDecimal} from "@/resources/util";
import './index.less';

const financeStatus = {

    PG: {
        label: 'PAGO',
        color: 'green'
    },
    C: {
        label: 'CANCELADO',
        color: 'red'
    },
    PE: {
        label: 'PENDENTE',
        color: ''
    }
}

export default {

    data() {

        return {

            fatura: {

                id: undefined,
                cliente: {

                    cidade: {

                        estado: {}
                    }
                },
                data: '',
                finance: {},
                fatura_produtos: [],
                valor: 0,
                valor_desconto: 0,
                valor_total: 0,
                organizacao: {

                    cidade: {

                        estado: {}
                    }
                },
            },
            loading: null,
            vistorias: [],
        }
    },
    created() {

        this.loading = true;

        http.get('painel/financeiro.obterFatura', {params: {id: parseInt(this.$route.params.id)}}).then(({data}) => {

            Object.assign(this.fatura, data, {

                data: moment(data.data).format('DD/MM/YYYY'),
                valor: `R$ ${formatDecimal(data.valor)}`,
                valor_desconto: `${formatDecimal(data.valor_desconto)} %`,
                valor_total: `R$ ${formatDecimal(data.valor_total)}`,
            });

            const {fatura_produtos} = data;

            if (fatura_produtos && fatura_produtos.length) {

                fatura_produtos.map(item => {

                    this.vistorias.push(Object.assign({}, {

                        id: item.movimento_id,
                        quantidade: item.quantidade,
                        produto: item.produto,
                        vistoria: item.produto.vistoria,
                        valor: `R$ ${formatDecimal(item.valor)}${item.produto.unidade_medida === 'M' ? ' m2' : ''}`,
                        valor_desconto: `R$ ${formatDecimal(item.valor_desconto)}`,
                        valor_total: `R$ ${formatDecimal(item.valor_total)}`,
                    }));
                });
            }

        }).catch(err => {

            this.$error({

                title: 'Ops, houve um erro.',
                content: err.message,
            });

        }).finally(() => this.loading = false)
    },
    methods: {

        handlePrint() {

            this.loading = true;

            http.get('painel/financeiro.fatura.obter', {
                params: {id: parseInt(this.fatura.id)},
                responseType: 'blob'
            }).then(data => {

                let url = window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}));

                this.windowOpen(url);

            }).catch(err => {

                this.$error({

                    title: 'Ops, houve um erro.',
                    content: err.message
                });

            }).finally(() => this.loading = false);
        },

        windowOpen(url) {

            if (!window.open(url, '_blank')) {

                this.$warning({

                    title: 'Ops, o navegador está bloqueando popups.',
                    content: h => <div><p>Clique no link para abrir: <a href={url} target="_blank">{url}</a></p></div>
                })
            }
        },
    },
    computed: {

        organizationCity() {

            let label = this.fatura.organizacao.cidade.id ? this.fatura.organizacao.cidade.nome : 'Não informado';

            if (this.fatura.organizacao.cidade.id && this.fatura.organizacao.cidade.estado.id) {

                label += `, ${this.fatura.organizacao.cidade.estado.uf}`;
            }

            return label;
        },

        clientName() {

            return this.fatura.cliente.pessoa === 'F' ? this.fatura.cliente.nome : this.fatura.cliente.nome_fantasia;
        },

        clientDocument() {

            return this.fatura.cliente.pessoa === 'F' ? this.fatura.cliente.cpf : this.fatura.cliente.cnpj;
        },

        clientAddress() {

            let label = this.fatura.cliente.logradouro ? this.fatura.cliente.logradouro : 'Não informado';

            if (this.fatura.cliente.logradouro && this.fatura.cliente.numero) {

                label += `, ${this.fatura.cliente.numero}`;
            }

            return label;
        },

        clientCity() {

            let label = this.fatura.cliente.cidade.id ? this.fatura.cliente.cidade.nome : 'Não informado';

            if (this.fatura.cliente.cidade.id && this.fatura.cliente.cidade.estado.id) {

                label += `, ${this.fatura.cliente.cidade.estado.uf}`;
            }

            return label;
        }
    },
    render() {

        return (

            <div class="view-fatura">
                <a-spin spinning={this.loading}>

                    <div class="view-header">
                        <div class="view-header-body">
                            <div class="view-header-start">
                                <g-icon icon={['fas', 'file-invoice-dollar']}/>
                                <h1>
                                    Visualização da Fatura
                                    <span class="view-header-id"> #{this.$route.params.id}</span>
                                </h1>
                            </div>
                            <div class='view-header-end'>
                                <a-button style="margin-right: 14px;"
                                          onClick={() => this.$router.push({name: 'faturas-list'})}>
                                    <g-icon icon={['fas', 'arrow-left']} class='fa-fixed'/>
                                    Voltar
                                </a-button>
                                <a-button onClick={() => this.handlePrint()}>
                                    <g-icon icon={['fas', 'print']} class='fa-fixed'/>
                                    Imprimir
                                </a-button>
                            </div>
                        </div>
                    </div>

                    <div class="view-fatura-body">
                        <a-row class='fatura-content' tpye='flex'>
                            <a-col class='left' {...{props: {sm: 24, md: 24, lg: 8, xl: 8}}}>
                                <a-card bordered={false}>
                                    <template slot="title">
                                        <g-icon icon={['far', 'university']} class='fa-fixed'/>
                                        Dados da organização
                                    </template>
                                    <a-card-meta title={this.fatura.organizacao.nome_fantasia}>
                                        <template slot='description'>
                                            <p>
                                                <g-icon icon={['far', 'id-badge']} class='fa-fixed'/>
                                                <strong>CNPJ: </strong>
                                                {this.fatura.organizacao.cnpj || 'Não informado'}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'map-marked-alt']} class='fa-fixed'/>
                                                <strong>Endereço: </strong>
                                                {this.fatura.organizacao.endereco || 'Não informado'}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'map-pin']} class='fa-fixed'/>
                                                <strong>Bairro: </strong>
                                                {this.fatura.organizacao.bairro || 'Não informado'}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'building']} class='fa-fixed'/>
                                                <strong>Cidade: </strong>
                                                {this.organizationCity}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'envelope']} class='fa-fixed'/>
                                                <strong>Email: </strong>
                                                {this.fatura.organizacao.email || 'Não informado'}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'phone-alt']} class='fa-fixed'/>
                                                <strong>Telefone: </strong>
                                                {this.fatura.organizacao.telefone || 'Não informado'}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'external-link']} class='fa-fixed'/>
                                                <strong>Site: </strong>
                                                {
                                                    this.fatura.organizacao.site ?
                                                        (<a target='_blank'
                                                            href={this.fatura.organizacao.site}>{this.fatura.organizacao.site}</a>)
                                                        : 'Não informado'
                                                }
                                            </p>
                                        </template>
                                    </a-card-meta>
                                    <a-divider/>
                                    <a-card-meta>
                                        <template slot='title'>
                                            <g-icon icon={['far', 'hand-holding-usd']} class='fa-fixed'/>
                                            Cobrado de {this.clientName}
                                        </template>
                                        <template slot='description'>
                                            <p>
                                                <g-icon icon={['far', 'id-badge']} class='fa-fixed'/>
                                                <strong>{this.fatura.cliente.pessoa === 'F' ? 'CPF' : 'CNPJ'}: </strong>
                                                {this.clientDocument}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'map-marked-alt']} class='fa-fixed'/>
                                                <strong>Endereço: </strong>
                                                {this.clientAddress}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'map-pin']} class='fa-fixed'/>
                                                <strong>Bairro: </strong>
                                                {this.fatura.cliente.bairro || 'Não informado'}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'building']} class='fa-fixed'/>
                                                <strong>Cidade: </strong>
                                                {this.clientCity}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'envelope']} class='fa-fixed'/>
                                                <strong>Email: </strong>
                                                {this.fatura.cliente.email || 'Não informado'}
                                            </p>
                                            <p>
                                                <g-icon icon={['far', 'phone-alt']} class='fa-fixed'/>
                                                <strong>Telefone: </strong>
                                                {this.fatura.cliente.telefone || 'Não informado'}
                                            </p>
                                        </template>
                                    </a-card-meta>
                                </a-card>
                            </a-col>

                            <a-col class='right' {...{props: {sm: 24, md: 24, lg: 16, xl: 16}}}>
                                <a-card class='card-fatura'>
                                    <template slot="title">
                                        <g-icon icon={['far', 'file-invoice-dollar']} style={{marginRight: '5px'}}/>
                                        Dados da fatura
                                    </template>

                                    <a-card-meta>
                                        <template slot='description'>
                                            <a-row type='flex' justify='space-between'>
                                                <p>
                                                    <g-icon icon={['far', 'calendar-day']} class='fa-fixed'/>
                                                    <strong>Data: </strong>
                                                    {this.fatura.data || 'Não informado'}
                                                </p>
                                                <p>
                                                    <strong>Situação: </strong>
                                                    {this.fatura.finance.status && (

                                                        <a-tag color={financeStatus[this.fatura.finance.status].color}>
                                                            {financeStatus[this.fatura.finance.status].label}
                                                        </a-tag>
                                                    )}
                                                </p>
                                                <p>
                                                    <g-icon icon={['far', 'money-bill-wave']} class='fa-fixed'/>
                                                    <strong>Valor: </strong>
                                                    {this.fatura.valor || 'Não informado'}
                                                </p>
                                                <p>
                                                    <g-icon icon={['far', 'badge-percent']} class='fa-fixed'/>
                                                    <strong>Desconto: </strong>
                                                    {this.fatura.valor_desconto || 'Não informado'}
                                                </p>
                                                <p>
                                                    <g-icon icon={['far', 'money-bill-wave']} class='fa-fixed'/>
                                                    <strong>Valor total: </strong>
                                                    {this.fatura.valor_total || 'Não informado'}
                                                </p>
                                            </a-row>
                                            <a-row>
                                                <p>
                                                    <g-icon icon={['far', 'comment-lines']} class='fa-fixed'/>
                                                    <strong>Descrição: </strong>
                                                    {this.fatura.descricao || 'Não informado'}
                                                </p>
                                            </a-row>
                                        </template>
                                    </a-card-meta>
                                </a-card>

                                <a-card class='card-vistorias'>
                                    <template slot="title">
                                        <g-icon icon={['far', 'clipboard-list']} style={{marginRight: '5px'}}/>
                                        Vistorias inclusas
                                    </template>
                                    <a-list
                                        dataSource={this.vistorias}
                                        renderItem={(item) => (
                                            <a-list-item>
                                                <a-list-item-meta
                                                    title={`#${item.id} - ${item.produto.nome} (Vistoria #${item.vistoria.id})`}>
                                                    <template slot='description'>
                                                        <g-icon icon={['far', 'map-marked-alt']} class='fa-fixed'/>
                                                        {item.vistoria.imovel.logradouro},
                                                        nº {item.vistoria.imovel.numero} - {item.vistoria.imovel.bairro}, {item.vistoria.imovel.cidade.nome}-{item.vistoria.imovel.cidade.estado.uf}
                                                    </template>
                                                </a-list-item-meta>
                                                <div class='vistoria-list-valor-wrapper'>
                                                    <div class='vistoria-list-valor-group'>
                                                        <div>
                                                            <div class="vistoria-list-title">
                                                                Valor:
                                                            </div>
                                                            <span>{item.valor}</span>
                                                        </div>
                                                        <div>
                                                            <div class="vistoria-list-title">
                                                                Desconto:
                                                            </div>
                                                            <span>{item.valor_desconto}</span>
                                                        </div>
                                                    </div>
                                                    <div class='vistoria-list-valor-group'>
                                                        <div>
                                                            <div class="vistoria-list-title">
                                                                Quantidade:
                                                            </div>
                                                            <span>
                                                                {item.quantidade}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div class="vistoria-list-title">
                                                                Valor Total:
                                                            </div>
                                                            <span>{item.valor_total}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </a-list-item>
                                        )}
                                    />
                                </a-card>
                            </a-col>
                        </a-row>
                    </div>
                </a-spin>
            </div>
        )
    }
}
